@import 'mpc-variables-and-mixins.scss';

.mobileshop .pcf-scope .flyout-container .title-container {
    height: 25px;

    .title-slot {
        margin: auto;

        svg {
            width: 25px;
            height: 25px;
            fill: $esBlack;
        }

        .notification-title.web {
            display: none;
        }

        .notification-title.mobile {
            display: initial;
        }
    }

    .close-flyout-btn {
        display: none;
    }
}