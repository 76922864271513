@import 'mpc-variables-and-mixins.scss';

.pcf-scope {
    /*by default fly out is disabled, fly out controller has to enable it*/
    display: none;
    position: absolute;
    float: right;
    padding: 9px 0 10px 0;
    margin-right: 30px;
    text-align: left;
    opacity: 0;
    transition: 0.5s ease;
    font-family: $esFontFamily;

    .flyout-container {
        max-height: 50px;
        height: 100%;
        width: 50px;

        .flyout-icon-container {
            position: relative;
            cursor: pointer;
            overflow: hidden;
            width: 50px;
            height: 50px;
            margin-top: 0px;
            margin-left: 0px;

            .products-counter {
                width: 11px;
                height: 12px;
                position: absolute;
                font-size: 10px;
                font-weight: 500;
                bottom: 13px;
                right: 11px;
                color: $esAllBlack;
                text-align: center;
                line-height: 1.4;
                transition: fill .3s ease-in-out, color .3s ease-in-out;
            }

            .flyout-icon {
                height: 18px;
                width: 20px;
                margin: auto;
                fill: $esGrey;
            }

            &:hover {
                .flyout-icon {
                    fill: $esDarkGrey;
                }

                .products-counter {
                    color: $esDarkGrey;
                }
            }

            &.mkt-headr-icon-wrapper {
                .mpc-scale {
                    height: 50px;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    width: 40px;

                    svg {
                        height: 100%;
                        display: none;

                        &.mpc-scale-2021-icon {
                            display: inline;
                        }
                    }
                }
            }

            &.notification_background {
                background-color: $esBlackNew;
                width: 40px;
                height: 40px;
                margin-top: 5px;
                margin-left: 5px;
                border-radius: 25px;

                .mpc-scale svg {
                    fill: $esWhite;
                    margin-left: -10px;
                    margin-top: -5px;
                }

                .products-counter {
                    color: $esWhite;
                    right: 5px;
                    bottom: 7px;
                }
            }
        }
    }

    &.headRIcon {
        padding: 0;
        position: static;
        margin-right: auto;
    }
}

.show-pcf-scope {
    opacity: 1;
}

.pcf-no-animation {
    transition: none;
}

.pcf-scope-position {
    position: static;
}

.mkt-active {
    .pcf-scope .flyout-container .flyout-icon-container {
        &.mkt-headr-icon-wrapper .mpc-scale {
            background-color: $esBlackNew;

            svg {
                fill: $esWhite;
            }
        }

        .products-counter {
            color: $esWhite;
        }
    }
}