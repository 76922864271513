@import 'mpc-variables-and-mixins.scss';

.pcf-scope .flyout-container {
    .notification {
        width: 400px;
        max-height: 250px;
        background-color: white;
        pointer-events: none;
        position: fixed;
        margin-left: -300px;
        padding-bottom: 25px;
        opacity: 0;
        transition: 0.5s ease;
        display: none;
        border-bottom: 1px solid $esGrey4;
        border-left: 1px solid $esGrey4;
        border-right: 1px solid $esGrey4;
        top: 50px;
        right: 0;

        .notification-container {
            margin-top: 25px;
            font-size: 13px;
        }

        .open-flyout-button {
            margin-top: 15px;
            max-width: 220px;
            height: 48px;
            line-height: 48px;
            background-color: $esRed;
            cursor: pointer;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            text-decoration: none;
            margin-left: auto;
            margin-right: auto;
            border-radius: 25px;
            transition: background-color 0.3s;

            &:hover {
                background-color: $esRedLight;
            }

            .btn-text {
                margin-right: 20px;
                color: $esWhite;
                font-size: $esFontSize;
                font-weight: $esFontWeight;
                letter-spacing: $esLetterSpacing;
                white-space: nowrap;
                padding-left: 30px;
            }

            .open-flyout-button-icon {
                height: 15px;
                width: 10px;
                position: relative;
                margin-left: -10px;
                fill: $esWhite;
                line-height: 15px;
                padding-right: 30px;

                svg {
                    height: 15px;
                }
            }
        }

        .close-flyout-btn-footer {
            display: block;
            border: 1px solid $esGrey2;
            border-radius: 25px;
            height: 50px;
            line-height: 50px;
            max-width: 185px;
            cursor: pointer;
            font-size: $esFontSize;
            font-weight: $esFontWeight;
            letter-spacing: $esLetterSpacing;
        }

        .footer-container {
            &.mobile {
                display: none;
            }
        }

        &.show-notification {
            opacity: 1;
            pointer-events: auto;
            display: block;
        }
    }

    .sticky-flyout-notification {
        @include sticky-flyout-notification;
    }
} 

.pcf-scope [data-portalmandanttoken="GLB"] {
    .notification {
        top: 89px;
    }
}