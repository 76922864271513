@import 'mpc-variables-and-mixins.scss';

.pcf-scope .flyout-container {
    .max-products-count-notification {
        .notification-container {
            padding: 0 32px;

            .message-max-reached-title,
            .message-max-reached,
            .message-more-required {
                text-align: center;
                font-weight: 500;
                color: $esAllBlack;
            }

            .message-max-reached-help {
                text-align: center;
                margin-top: 20px;
                color: $esAllBlack;
            }
        }

        .footer-container {
            display: block;

            .close-flyout-btn-footer {
                display: none;
            }
        }
    }
} 