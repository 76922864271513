@import 'mpc-variables-and-mixins.scss';

.pcf-flyout-content-scope,
.pcf-scope {
    .compare-list-btn {
        background-color: $esRed;
        cursor: pointer;
        width: 160px;
        height: 48px;
        line-height: 48px;
        border-radius: 25px;
        margin: 15px auto 30px auto;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        text-decoration: none;
        transition: background-color 0.3s;

        &:hover {
            background-color: $esRedLight;
        }

        &.disabled {
            background-color: white;
            background-color: $esGrey2;
            color: $esBlack;
            pointer-events: none;
        }

        .btn-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            text-decoration: none;
            width: 100%;
            height: 100%;

            .btn-text {
                margin-right: 20px;
                color: $esWhite;
                font-size: $esFontSize;
                font-weight: $esFontWeight;
                letter-spacing: $esLetterSpacing;
                line-height: normal;
                padding: 0 30px;
            }

            .compare-button-icon {
                height: 15px;
                width: 10px;
                position: relative;
                margin-left: -35px;
                fill: $esWhite;
                line-height: normal;
                padding-right: 30px;

                svg {
                    height: 100%;
                }
            }
        }
    }
}