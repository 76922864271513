@import 'mpc-variables-and-mixins.scss';
// Don't set any font-size here for better maintainance, because it will be controled by MF component
.mpc-scope .toolbar-button-wrapper {
    text-align: center;
    line-height: 20px;
    color: $esAllBlack;
    font-weight: 500;
    transition: background-color 0.3s, color 0.3s;

    span {
        vertical-align: middle;
        display: inline-block;
        padding-top: 10px;
    }

    .comparison-icon {
        width: 24px;
        height: 24px;
        fill: $esAllBlack;
        vertical-align: top;
        padding-top: 5px;
        line-height: 30px;
        display: inline-block;

        svg {
            vertical-align: middle;
            margin: auto;
        }
    }
}

// This selector is outside of the MPC, but it keeps the active feature styles in one service
// without refactor the button out of MPC to MobileFrontend
.articlelist-toolbar .toolbar-button.compare-button.active {
    background-color: white;
    border-color: $esAllBlack;

    .mpc-scope .toolbar-button-wrapper {
        color: $esAllBlack;

        .comparison-icon {
            fill: $esAllBlack;
        }
    }
}

.fas_scope-fas .fas_fragment_ph .mpc-scope {
    .toolbar-button-wrapper {
        background-color: $esLightGrey;
        border-radius: 50px;
        margin: 0 auto 10px;
        display: flex;
        cursor: pointer;
        height: 48px;
        width: calc(50vw - 15px);
        align-items: center;
        justify-content: center;
        color: #1e1e1e;
        flex-direction: row-reverse;
        font-weight: 500;
        margin-right: 10px;
        transition: background-color 0.3s, color 0.3s;
        font-size: 14px;

        span {
            padding-top: initial;
        }

        .comparison-icon {
            fill: $esAllBlack;
            margin-right: 10px;
            padding-top: initial;
            line-height: 20px;
            transition: fill 0.3s;
        }
    }

    .active {
        background-color: $esAllBlack;
        color: $esWhite;

        .comparison-icon {
            fill: $esWhite;
        }
    }
}

// html.mobileshop,
// html.tablet {
//     .fas-categorypage-scope .fas_scope-fas .toolbar-button-wrapper {
//         width: 100%;
//         margin: initial;
//     }
// }

@media screen and (max-width: 768px - 1) {
    html.mobileshop .fas-categorypage-scope .fas_scope-fas .toolbar-button-wrapper {        
        span {
            display: none;
        }

        .comparison-icon {
            margin-right: initial;
        }
    }
}

@media screen and (max-width: 1199px) {
    .fas-categorypage-scope .fas_scope-fas .fas_fragment_ph .mpc-scope .toolbar-button-wrapper {
        width: 100%;
        margin: initial;
    }
}