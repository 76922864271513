@import 'mpc-variables-and-mixins.scss';

.mobileshop .mpc-scope.compare-button-wrapper {
    padding: 6px 12px 6px;

    .compare-button {
        margin-top: 0;
        width: 100%;
        font-size: 14px;
        font-weight: $esFontWeight;
        height: 48px;
        line-height: 26px;
        border-radius: 24px;
        color: $esAllBlack;
        background: $esLightGrey;
        transition: background-color 0.3s;
        border: none;

        &:hover {
            background: $esMidGrey;
            transition: background-color 0.3s;
        }
    }
}

html.mobileshop .modal-body .mpc-scope.compare-button-wrapper {
    padding: 6px 5px 6px;
}

html.mobileshop .pdp-details_page_container {
    .mpc-scope.compare-button-wrapper {
        .compare-button {
            height: 48px;
            border-radius: 50px;
            border: 0;
            padding: 4px 30px;
            font-size: 13px;
            background-color: $esLightGrey;
            color: $esAllBlack;
        }
    }
}

html.mobileshop .om-compareShareBookmark_mobile_button_container {
    .mpc-scope.compare-button-wrapper {
        padding: 4px 0;
        background-color: unset;
    }
}
