@import 'mpc-variables-and-mixins.scss';

.pcf-flyout-content-scope .flyout {
    .flyout-header {
        flex-direction: column;
        justify-content: space-between;
        position: relative;

        .title-container {
            margin-bottom: 5px;
            border-bottom: 1px solid $esBlack;

            .title-slot {
                margin-left: -20px;

                .title-icon {
                    display: none;
                }
            }

            @include close-flyout-btn;
        }

        @include navigation-tabs-mixin;
    }
}