@import 'mpc-variables-and-mixins.scss';

.mpc-scope.scale-tile-container {
    position: absolute;
    width: 38px;
    height: 38px;
    margin: 10px 0 0 10px;
    border-radius: 19px;
    border: 1px solid $esMidGrey;
    cursor: pointer;
    background-color: $esWhite;
    display: none;
    z-index: 150;
    transition: border-color 0.3s, background-color 0.3s;

    svg {
        fill: $esAllBlack;
        transition: fill 0.3s;
    }

    &:hover {
        border-color: $esAllBlack;

        svg {
            fill: $esAllBlack;
        }
    }

    .article-tile.hovercontent-open & {
        display: block;
    }

    &.active {
        border-color: $esAllBlack;
        background-color: $esAllBlack;

        .comparison-empty-icon {
            display: none;
        }

        .comparison-icon {
            display: block;

            svg {
                fill: $esWhite;
            }
        }
    }

    .comparison-empty-icon,
    .comparison-icon {
        line-height: 33px;

        svg {
            transition: fill 0.3s;
            vertical-align: middle;
            display: none;

            &.mpc-scale-2021-icon {
                display: inline;
            }
        }
    }

    &.has-focus-keyboard {
        outline: 2px solid $esAllBlack;
    }

    .comparison-empty-icon {
        width: 22px;
        height: 32px;
        margin: auto;
        fill: #d4d6d5;
    }

    .comparison-icon {
        width: 22px;
        height: 32px;
        margin: auto;
        fill: $esAllBlack;
        display: none;
    }
}

.mobile-touchpoints {
    display: none;
}

.mpc-scope.scale-tile-container.ats {
    display: block;
    border: none;
    margin: 0;
}