@import 'mpc-variables-and-mixins.scss';

.mobileshop {
    .pcf-scope .flyout-container .empty-list-notification {
        padding: 35px 0;

        .notification-container .description-text {
            margin: initial;
            margin-left: 25px;
            margin-right: 25px;
        }

        .footer-container {
            padding: 0;
        }

        .navigate-to-products-button {
            height: 48px;
            line-height: 48px;
            border-radius: 24px;
            font-weight: 500;
            font-size: 13px;
        }
    }
}