@import 'mpc-variables-and-mixins.scss';

.mpc-scope .compare-button {
    font-size: 12px;
    font-weight: $esFontWeight;
    color: $esAllBlack;
    height: 40px;
    border-radius: 20px;
    text-decoration: none;
    margin: 5px auto auto auto;
    background: $esLightGrey;
    padding: 0;
    transition: background-color 0.3s;
    border: none;
    letter-spacing: $esLetterSpacing;

    &:hover {
        background: $esMidGrey;
        transition: background-color 0.3s;
    }

    .comparison-icon {
        width: 24px;
        margin-right: 12px;
        fill: $esBlack;
        display: inline-block;

        svg {
            vertical-align: middle;
            margin: auto;
        }
    }

    .btn-text {
        vertical-align: middle;
        line-height: 48px;
    }
}

.add-to-basket .basketbuttons, 
.pdp-details_page_container {
    .compare-button-wrapper {
        display: contents;
    }

    .mpc-scope {
        &.mpc-compare-btn .compare-button {
            margin: inherit;
            width: 100%;
            padding: 8px;
            border: none;
            background-color: $esLightGrey;
            border-radius: 50px;
            cursor: pointer;
            align-items: center;
            line-height: normal;
            font-size: 13px;
            color: $esAllBlack;
            letter-spacing: $esLetterSpacing;

            &:hover {
                background: $esMidGrey;
                transition: background-color 0.3s;
            }

            .comparison-icon {
                fill: black;
            }

            .btn-text {
                line-height: normal;
            }
        }
    }
}

.mpc-scope.compare-button-wrapper.touchpoint.new-om .compare-button {
    background-color: #fff;
    border: 1px solid #d3d4d4;
    border-radius: 50px;
    cursor: pointer;
    align-items: center;
    height: 48px;
    width: 48px;
    margin: 0;
    transition: border-color 0.3s;

    .comparison-icon {
        margin: 0;
    }

    &:hover {
        border-color: $esAllBlack;
    }
}
