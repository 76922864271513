@import 'mpc-variables-and-mixins.scss';

.pcf-flyout-content-scope {
    .flyout {
        .flyout-content {
            .list-container {
                .clear-list-btn {
                    margin-right: 43px;
                }
            }
        }

        .flyout-footer {
            .close-flyout-btn-footer {
                display: none;
            }
        }
    }
}
