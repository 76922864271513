@import 'mpc-variables-and-mixins.scss';

.pcf-flyout-content-scope .flyout-footer {
    margin-bottom: 30px;

    .compare-list-btn {
        margin: 0 auto;

        .comparison-page-link {
            &.has-focus-mouse {
                outline: none;
            }

            &.has-focus-keyboard {
                outline-offset: 3px;
                outline: 1px solid $esAllBlack;
                border-radius: 25px;
            }
        }
    }

    .close-flyout-btn-footer {
        height: 20px;
        background-color: $esGrey3;
        cursor: pointer;
        position: relative;
        display: none;

        .close-arrow {
            cursor: pointer;
            width: 15px;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            position: absolute;
            fill: $esGrey;
            line-height: 20px;
            height: 20px;
        }
    }
}