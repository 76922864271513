@import 'mpc-variables-and-mixins.scss';

.mobileshop {
    .pcf-scope .flyout-container .new-product-notification {
        max-height: 265px;

        .product-info-container {
            margin-top: 30px;
            padding-bottom: unset;

            .image-slot {
                width: 120px;
                height: 120px;
                line-height: 118px;
                border: none;
                margin-right: 10px;
            }

            .description-container {
                display: flex;
                flex-direction: column;
                justify-content: start;
                height: 122px;
                padding-right: 12px;

                .title {
                    color: $esAllBlack;
                    font-size: 13px;
                    font-weight: 500;
                }

                .description {
                    margin-top: 15px;
                    font-size: 13px;
                    line-height: 22px;
                }
            }
        }

        @media (max-width: 360px) {
            .product-info-container {
                align-items: center;

                .description-container {
                    height: auto;
                }
            }
        }
    }
}