.mobileshop .pcf-scope .footer-container .compare-list-btn {
    display: block;
    height: 48px;
    line-height: 48px;
    margin: 0;
    width: initial;

    .btn-container {
        padding: inherit;

        .compare-button-icon {
            line-height: 15px;
        }
    }
}
