@import 'mpc-variables-and-mixins.scss';

.pcf-flyout-content-scope,
.pcf-scope {
    .title-container {
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
        height: 50px;
        position: relative;

        .title-slot {
            display: flex;
            align-items: center;

            .notification-title {
                color: $esAllBlack;
                margin-left: 20px;
                font-weight: 500;
                font-size: 16px;
                text-transform: uppercase;

                &.mobile {
                    display: none;
                }
            }
        }

        @include close-flyout-btn;
    }
}
