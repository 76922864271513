@import 'mpc-variables-and-mixins.scss';

.mobileshop {
    .pcf-scope .flyout-container .max-products-count-notification {
        .notification-container {
            padding: initial;
            margin-left: 30px;

            .message-max-reached-title,
            .message-max-reached,
            .message-more-required,
            .message-max-reached-help {
                text-align: left;
            }
        }
    }
}