@import 'mpc-variables-and-mixins.scss';

.mobileshop {
    .pcf-scope .flyout-container .notification {
        margin-left: auto;
        margin-right: auto;
        z-index: 1;
        width: 100%;
        position: relative;
        padding-top: 35px;
        padding-bottom: 35px;
        border: unset;
        top: inherit;

        .title-container {
            flex-direction: row;
            justify-content: flex-start;

            .title-slot {
                margin: initial;
                margin-left: 10px;
            }
        }

        .title-container .close-flyout-btn {
            display: none;
        }

        .btn-text {
            white-space: nowrap;
        }

        .containers-separation-line {
            display: none;
        }

        .product-info-container .open-flyout-button {
            display: none;
        }

        .footer-container {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;

            &.desktop {
                display: none;
            }

            .btn-footer {
                margin: 15px 5px 5px;
                flex: 1;
            }

            .close-flyout-btn-footer {
                display: block;
                border: none;
                height: 48px;
                line-height: 48px;
                max-width: 185px;
                cursor: pointer;
                color: $esAllBlack;
                font-weight: $esFontWeight;
                letter-spacing: $esLetterSpacing;
                font-size: $esFontSize;
                text-align: center;
                background-color: $esLightGrey;
            }

            .open-flyout-button {
                background-color: $esLightGrey;
                border: none;
                height: 40px;
                max-width: 185px;
                cursor: pointer;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                line-height: 20px;
                color: $esAllBlack;
                font-weight: $esFontWeight;
                letter-spacing: $esLetterSpacing;
                font-size: $esFontSize;
                text-align: center;

                svg {
                    height: 100%;
                }
            }
        }
    }
}